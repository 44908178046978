<template>
  <div>
    <VTitle :title="$t('app.profile')" class="route-title" />

    <UserHeader
      :user="user"
      :avatar="user?.avatar"
      :is-deleting="isDeleting"
      display-avatar-actions
      @update:avatar="onUpdateAvatar"
      @delete:avatar="onDeleteAvatar"
    >
      <template #tabs>
        <div class="flex justify-between">
          <VTabs
            :current-tab="selectedTab"
            :tabs="tabs.slice(0, -1)"
            @click="onClickRedirect"
          />

          <VTabs
            :current-tab="selectedTab"
            :tabs="tabs.slice(-1)"
            @click="onClickRedirect"
          />
        </div>
      </template>
    </UserHeader>

    <router-view :id="user.id" :user="user" class="mt-10" />
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
// Composables
import useModal from "@/composables/useModal";
import useTabs from "@/composables/useTabs";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import UserHeader from "@/views/administration/users/UserHeader";
import VTabs from "@/components/VTabs";

export default {
  components: {
    VTitle,
    UserHeader,
    VTabs
  },
  setup() {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const currentRoute = useRoute();
    const router = useRouter();

    // Composables
    const { hideModal } = useModal();
    const { buildTab } = useTabs();
    const { request, isSuccess } = useRequest();

    // Constants
    const route = "profile";

    // DATA
    const isDeleting = ref(false);

    // COMPUTED
    const user = computed(() => store.getters["user/user"]);

    const tabs = computed(() => [
      buildTab({ name: "details" }),
      buildTab({ name: "memberships" }),
      buildTab({
        name: "ui-settings",
        label: t("app.ui_settings")
      }),
      buildTab({ name: "feedback" }),
      buildTab({ name: "logs" })
    ]);

    const selectedTab = computed(() => {
      const allTabs = tabs.value.reduce((acc, tab) => {
        return {
          ...acc,
          [`${route}-${tab.name}`]: tab.name
        };
      }, {});

      return allTabs[currentRoute.name] ?? "";
    });

    // METHODS
    const onUpdateAvatar = async e => {
      const formData = new FormData();
      formData.append("avatar_file", e.target.files[0]);

      const response = await request({
        endpoint: "my.avatar.update",
        data: formData
      });

      if (isSuccess(response)) {
        store.dispatch(
          "user/setAvatar",
          response?.payload?.data?.avatar?.view_path
        );
      }
    };

    const onDeleteAvatar = async () => {
      isDeleting.value = true;
      const response = await request({
        endpoint: "my.avatar.delete"
      });

      if (isSuccess(response)) {
        store.dispatch(
          "user/setAvatar",
          response?.payload?.data?.avatar?.view_path
        );
        hideModal();
      }

      isDeleting.value = false;
    };

    const getData = async () => {
      const response = await request({
        endpoint: "my.profile",
        showToaster: false
      });

      store.dispatch("user/updateUser", response?.payload?.data || {});
    };

    const onClickRedirect = tab => {
      router.push({ name: `${route}-${tab}` });
    };

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getData();
    });

    return {
      onClickRedirect,
      tabs,
      user,
      onUpdateAvatar,
      onDeleteAvatar,
      isDeleting,
      selectedTab
    };
  }
};
</script>
